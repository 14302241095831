<template>
  <v-dialog
    v-model="dialog"
    width="378"
    content-class="bg-w-silver text-center"
  >
    <div class="text-right">
      <button @click.stop="dialog=false" class="btn-close bg-primary">
        <div class="btn-close__icon" />
      </button>
    </div>

    <div class="pa-2 mb-2">
      <div v-if="extraMessage" class="mt-2 --d-red">
        {{extraMessage}}
      </div>
      <div v-else>
        この会員を削除します。よろしいですか？
      </div>
    </div>

    <v-btn
      v-if="canDelete"
      elevation="0"
      rounded
      width="262"
      height="57"
      class="bg-silver my-3 --prm font-weight-bold"
      @click="confirm"
    >
      削除する
    </v-btn>
    <v-btn
      elevation="0"
      color="primary"
      class="--w-bold mb-3 font-weight-bold"
      @click="back"
      width="262"
      height="57"
      rounded
    >
      {{ $t("buttons.return") }}
    </v-btn>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag'
export default {
  props: {
    value: {
      type: Boolean
    },
    subMemberId: {
      type: Number
    }
  },
  data () {
    return {
      extraMessage: null
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    canDelete () {
      return !this.$apollo.loading && !this.extraMessage
    }
  },
  methods: {
    back () {
      this.dialog = false
    },
    confirm () {
      this.$emit('confirm')
    }
  },
  apollo: {
    extraMessage: {
      skip () {
        return !this.value
      },
      query: gql`query mySubMemberDeleteWarning(
        $subMemberId: Int!
      ) { mySubMemberDeleteWarning(subMemberId: $subMemberId) {
        warnings {
          message
        }
      } }`,
      update: data => data.mySubMemberDeleteWarning.warnings.map(w => w.message).join('\n'),
      variables () {
        return {
          subMemberId: this.subMemberId
        }
      }
    }
  }
}
</script>
