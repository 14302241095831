<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/member-info">
        {{ $t("menu.main.membershipInformation") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container px-5 pb-15">
      <template v-if="!isNew">
        <!--================ START Login-information ================-->
        <h2 class="text-heading bg-orange --white text-center pt-6 mt-3 pb-3">
          {{ accType }}ログイン情報を変更する
        </h2>
        <v-form
          ref="loginInfoForm"
          lazy-validation
          class="bg-w-silver rounded px-5 py-7"
        >
          <!-- START Email -->
          <RequiredLabel :name="$t('common.loginId')"/>
          <v-text-field
            :rules="[$rules.required, $rules.email]"
            v-model="loginEmail"
            x-large
            solo
            flat
            type="email"
            class="mt-3 rounded-lg"
            outlined
          />
          <!-- END Email -->

          <!-- START Password -->
          <RequiredLabel :name="$t('common.password')"/>
          <v-text-field
            :rules="rules.password"
            @keydown.space.prevent
            solo
            flat
            type="password"
            autocomplete="new-password"
            v-model="password"
            class="mt-3 rounded-lg"
            outlined
          />
          <!-- End Password -->

          <div class="text-center">
            <!-- START Save-login-info Button -->
            <v-btn
              elevation="0"
              @click="saveLoginInfoForm"
              x-large
              width="270"
              rounded
              class="text-medium--prm mt-1 mx-auto"
              color="btn-grad--orange"
            >

              {{ $t("buttons.save") }}
            </v-btn>
            <!-- END Save-login-info Button -->
          </div>
        </v-form>
        <!--================ END Login-information ================-->
      </template>

      <!--================ START Member-information ================-->
      <h2 class="bg-orange text-heading--white text-center mt-3 pt-6 pb-3">
        <template v-if="isNew">
          {{ accType }}を追加する
        </template>
        <template v-else>
          {{ accType }}情報を変更する
        </template>
      </h2>
      <v-form
        ref="memberInfoForm"
        class="bg-w-silver px-5 py-7"
      >
        <template v-if="isNew">
          <!-- START Email -->
          <RequiredLabel :name="$t('common.loginId')"/>
          <v-text-field
            :rules="[$rules.required, $rules.email]"
            v-model="loginEmail"
            x-large
            solo
            flat
            type="email"
            class="mt-3 rounded-lg"
            outlined
          />
          <!-- END Email -->

          <!-- START Password -->
          <RequiredLabel :name="$t('common.password')"/>
          <v-text-field
            :rules="rules.password"
            @keydown.space.prevent
            solo
            flat
            type="password"
            autocomplete="new-password"
            v-model="password"
            class="mt-3 rounded-lg"
            outlined
          />
          <!-- End Password -->
        </template>

        <!-- START Name -->
        <RequiredLabel :name="$t('common.yourName')"/>
        <div class="d-flex mt-3">
          <v-text-field
            :rules="rules.name"
            v-model="surname"
            :placeholder="$t('common.surname')"
            x-large
            solo
            flat
            type="text"
            class="mr-2 rounded-lg"
            outlined
          />
          <v-text-field
            :rules="rules.name"
            v-model="firstName"
            :placeholder="$t('common.firstName')"
            x-large
            solo
            flat
            type="text"
            class="ml-2 rounded-lg"
            outlined
          />
        </div>
        <!-- END Name -->

        <!-- START Katakana-name -->
        <RequiredLabel :name="$t('common.katakana')"/>
        <div class="d-flex mt-3">
          <v-text-field
            :rules="rules.name"
            v-model="surnameKana"
            :placeholder="$t('common.sei')"
            x-large
            solo
            flat
            type="text"
            class="mr-2 rounded-lg"
            outlined
          />
          <v-text-field
            :rules="rules.name"
            v-model="firstNameKana"
            :placeholder="$t('common.mei')"
            x-large
            solo
            flat
            type="text"
            class="ml-2 rounded-lg"
            outlined
          />
        </div>
        <!-- END Katakana-name -->

        <!-- START Date-of-birth -->
        <RequiredLabel :name="$t('common.dateOfBirth')">
          <span class="--c-red"> {{ $t("rules.childrenAge") }} </span>
        </RequiredLabel>
        <div class="d-flex mt-3">
          <v-select
            :items="yearList"
            v-model="yearOfBirth"
            :rules="rules.isRequired"
            ref="year"
            @change="$refs.month.validate(); $refs.day.validate()"
            placeholder="YYYY"
            solo
            flat
            outlined
          />
          <label class="mt-9 mx-2"> {{ $t("common.year") }} </label>
          <v-select
            :items="monthList"
            v-model="monthOfBirth"
            :rules="rules.isRequired"
            ref="month"
            @change="$refs.year.validate(); $refs.day.validate()"
            placeholder="MM"
            solo
            flat
            outlined
          />
          <label class="mt-9 mx-2"> {{ $t("common.month") }} </label>
          <v-text-field
            type="number"
            class="no-input-arrow"
            v-model="dayOfBirth"
            :rules="rules.dayOfBirth"
            ref="day"
            @change="$refs.year.validate(); $refs.month.validate()"
            placeholder="DD"
            outlined
            solo
            flat
          />
          <label class="mt-9 mx-2"> {{ $t("common.day") }} </label>
        </div>
        <!-- END Date-of-birth -->

        <!-- START Relationship -->
        <RequiredLabel :name="$t('common.relationship')"/>
        <enum-select
          v-model="relationship"
          enum-name="relationships"
          class="mt-3 rounded-lg"
          required
          solo
          flat
          outlined
        />
        <!-- END Relationship -->

        <!-- START Gender -->
        <RequiredLabel :name="$t('common.gender')"/>
        <v-radio-group
          v-model="gender"
          :rules="[$rules.required]"
          row
          class="mt-1"
        >
          <v-radio
            value="Male"
            :label="$t('common.male')"
            :ripple="false"
            class="mr-8"
          />
          <v-radio
            value="Female"
            :label="$t('common.female')"
            :ripple="false"
            class="mr-8"
          />
          <v-radio
            value="Other"
            :label="$t('common.other')"
            :ripple="false"
            class="text-medium-gray"
          />
        </v-radio-group>
        <!-- END Gender -->

        <!-- Checkbox -> Use the same address as a regular member -->
        <v-checkbox
          v-model="isUsingSameAddress"
          :label="$t('memberInfo.useSameAddressAsMember')"
          :ripple="false"
          hide-details
          class="mt-0 mb-12"
        />

        <template v-if="!isUsingSameAddress">
          <!-- START Postal-code -->
          <RequiredLabel :name="$t('common.postalCode')"/>
          <label class="--medium-gray d-inline-block mt-1">
            {{ $t("rules.hyphenInHalfWidthNumbersNotice") }}
          </label>
          <div class="d-flex">
            <postal-code-input v-model="postalCode" class="mt-3" outlined/>
            <v-btn
              elevation="0"
              rounded
              large
              outlined
              class="bg-white --prm ml-5 mr-10 mt-5"
              @click="searchPostalCode"
            >
              {{ $t("buttons.findAnAddress") }}
            </v-btn>
          </div>
          <!-- END Postal-code -->

          <RequiredLabel :name="$t('common.province')"/>
          <!-- START State/prefecture -->
          <enum-select
            v-model="prefecture"
            enum-name="prefectures"
            select-name
            class="mt-3 rounded-lg"
            required
            solo
            flat
            outlined
          />
          <!-- END State/prefecture -->

          <!-- START City/locality -->
          <RequiredLabel :name="$t('common.district')"/>
          <v-text-field
            :rules="rules.text"
            v-model="locality"
            x-large
            solo
            flat
            type="text"
            class="mt-3 rounded-lg"
            outlined
          />
          <!-- END City/locality -->

          <!-- START address1 -->
          <RequiredLabel :name="$t('common.street')"/>
          <v-text-field
            :rules="rules.text"
            v-model="address1"
            x-large
            solo
            flat
            type="text"
            class="mt-3 rounded-lg"
            outlined
          />
          <!-- END address1 -->

          <!-- START Building/Apartment name, etc. -->
          <RequiredLabel :required="false" :name="$t('common.buildingName')"/>
          <v-text-field
            :rules="rules.optionalText"
            v-model="address2"
            x-large
            solo
            flat
            type="text"
            class="mt-2 rounded-lg"
            outlined
          />
          <!-- START Building/Apartment name, etc. -->
        </template>

        <!-- START Phone-number -->
        <RequiredLabel :name="$t('common.phoneNumber')"/>
        <label class="--medium-gray d-inline-block mt-1">
          {{ $t("rules.hyphenInHalfWidthNumbersNotice") }}
        </label>
        <v-text-field
          :rules="rules.phoneNumber"
          v-model="tel"
          placeholder="000-123-1234"
          x-large
          solo
          flat
          type="text"
          class="mt-3 rounded-lg"
          outlined
        />
        <!-- END Phone-number -->

        <div class="text-center">
          <!-- START Save-member-info Button -->
          <v-btn
            elevation="0"
            @click="saveMemberInfoForm"
            x-large
            rounded
            width="270"
            class="bg-orange--grad text-medium--white mt-5 mx-auto"
          >
            <span class="mx-auto">{{ $t("buttons.save") }}</span>
            <IconRight/>
          </v-btn>
          <!-- END Save-member-info Button -->

          <!-- START Delete-this-member Button -->
          <v-btn
            elevation="0"
            @click="initDelete"
            :disabled="isNew"
            x-large
            rounded
            outlined
            width="270"
            class="bg-dark-red text-medium--prm my-9 mx-auto"
          >
            {{ $t("buttons.deleteThisMember") }}
          </v-btn>
          <!-- END Delete-this-member Button -->
        </div>
      </v-form>
      <!--================ START Member-information ================-->
    </div>
    <delete-confirmation v-model="confirmDialog" :subMemberId="id" @confirm="deleteThisMember" />
  </div>
</template>

<script>
import {
  checkPassword,
  checkPhoneNumber, checkNumber
} from '@/utils/validate'
import { getYearListOfAge, getMonthList, isValidDate } from '@/utils/get-date'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import RequiredLabel from '../../../../components/RequiredLabel'
import EnumSelect from '../../../../components/EnumSelects/EnumSelect.vue'
import { splitName } from '@/utils/string'
import PostalCodeInput from '../../../../components/Inputs/PostalCodeInput.vue'
import IconRight from '../../../../components/icons/IconRight'
import {
  resolvePostalCode
} from '@/api/postal-code'
import DeleteConfirmation from './DeleteConfirmation.vue'

export default {
  name: 'MemberInfoMemberDetail',
  components: {
    Breadcrumbs,
    RequiredLabel,
    EnumSelect,
    PostalCodeInput,
    IconRight,
    DeleteConfirmation
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('getMainContact', { apollo: this.$apollo })
      })
    })

    if (!this.isNew) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          const member = await this.$store.dispatch('mySubMember', parseInt(this.id, 10))
          this.subMemberTypeId = member.subMemberTypeId
          this.loginEmail = member.memberUser?.email
          const kanji = splitName(member.name)
          this.surname = kanji.surname
          this.firstName = kanji.firstName
          const kana = splitName(member.nameKana)
          this.surnameKana = kana.surname
          this.firstNameKana = kana.firstName
          const bDaySplit = member.birthday?.split('-') ?? []
          this.yearOfBirth = parseInt(bDaySplit[0])
          this.monthOfBirth = parseInt(bDaySplit[1])
          this.dayOfBirth = parseInt(bDaySplit[2])
          this.relationship = member.relationshipId
          this.gender = member.sex
          this.postalCode = member.postalCode
          this.prefecture = member.prefecture
          this.locality = member.locality
          this.address1 = member.address1
          this.address2 = member.address2
          this.tel = member.tel
          this.email = member.email
        })
      })
    }
  },
  computed: {
    yearList () {
      return getYearListOfAge(18)
    },
    monthList () {
      return getMonthList()
    },
    name () {
      return `${this.surname || ''} ${this.firstName}`
    },
    nameKana () {
      return `${this.surnameKana || ''} ${this.firstNameKana || ''}`
    },
    birthday () {
      return `${this.yearOfBirth}-${String(this.monthOfBirth).padStart(2, '0')}-${String(this.dayOfBirth).padStart(2, '0')}`
    },
    baseInfo () {
      let vars = {
        name: this.name,
        nameKana: this.nameKana,
        birthday: this.birthday,
        relationshipId: this.relationship,
        sex: this.gender,
        email: this.email,
        tel: this.tel,
        postalCode: this.postalCode,
        prefecture: this.prefecture,
        locality: this.locality,
        address1: this.address1,
        address2: this.address2
      }

      if (this.isUsingSameAddress) {
        vars = {
          ...vars,
          postalCode: this.$store.state.memberInfo.mainContact?.postalCode,
          prefecture: this.$store.state.memberInfo.mainContact?.prefecture,
          locality: this.$store.state.memberInfo.mainContact?.locality,
          address1: this.$store.state.memberInfo.mainContact?.address1,
          address2: this.$store.state.memberInfo.mainContact?.address2
        }
      }
      return vars
    },
    loginInfo () {
      return {
        email: this.loginEmail,
        password: this.password
      }
    },
    accType () {
      return this.subMemberTypeId === 1 ? '副会員' : 'ファミリー会員'
    }
  },
  watch: {
    isUsingSameAddress (v) {
      if (v) {
        // make sure that the main user has registered address
        const required = ['postalCode', 'prefecture', 'locality', 'address1']
        if (required.map(key => this.baseInfo[key]).some(v => !v)) {
          this.$nextTick(() => {
            this.isUsingSameAddress = false
          })
          this.$toast.error('正会員の連絡先住所が登録されていません。入力フォームに直接住所をご入力頂くか、正会員の会員ページ内連絡先変更画面より連絡先をご登録の上再度お試しください。')
        }
      }
    }
  },
  methods: {
    // Form-submit ---------
    async saveLoginInfoForm () {
      if (this.$refs.loginInfoForm.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            if (this.isNew) {
              await this.$store.dispatch('createMySubMemberInfo', {
                subMemberTypeId: this.subMemberTypeId,
                memberUser: this.loginInfo
              })
            } else {
              await this.$store.dispatch('updateMySubMemberInfo', {
                subMemberTypeId: this.subMemberTypeId,
                id: this.id,
                memberUser: this.loginInfo
              })
            }

            await this.$router.push('/home/member-info/member')
          })
        })
      }
    },
    async saveMemberInfoForm () {
      if (this.$refs.memberInfoForm.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            if (this.isNew) {
              // must create the login info at the same time
              await this.$store.dispatch('createMySubMemberInfo', {
                subMemberTypeId: this.subMemberTypeId,
                memberUser: this.loginInfo,
                baseInfo: this.baseInfo
              })
            } else {
              await this.$store.dispatch('updateMySubMemberInfo', {
                subMemberTypeId: this.subMemberTypeId,
                id: this.id,
                baseInfo: this.baseInfo
              })
            }
            await this.$router.push('/home/member-info/member')
          })
        })
      }
    },
    initDelete () {
      this.confirmDialog = true
    },
    async deleteThisMember () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('deleteMySubMember', this.id)
          await this.$router.push('/home/member-info/member')
        })
      })
    },
    async searchPostalCode () {
      if (this.postalCode) {
        try {
          await this.$doLoading(async () => {
            const resolved = await resolvePostalCode(this.postalCode)
            if (resolved) {
              this.prefecture = resolved.address1
              this.locality = resolved.address2
              this.address1 = resolved.address3
            }
          })
        } catch (e) {
          this.$toast.error('郵便番号検索に失敗しました。お手数をおかけしますが、各項目を手動でご入力下さい。')
        }
      }
    }
  },
  data () {
    return {
      id: Number(this.$route.params.id),
      isNew: !Number(this.$route.params.id),
      subMemberTypeId: 2,
      loginEmail: null,
      password: null,
      email: null,
      surname: null,
      firstName: null,
      surnameKana: null,
      firstNameKana: null,
      yearOfBirth: null,
      monthOfBirth: null,
      dayOfBirth: null,
      relationship: null,
      gender: null,
      isUsingSameAddress: false,
      postalCode: null,
      prefecture: null,
      locality: null,
      address1: null,
      address2: null,
      tel: null,
      confirmDialog: false,

      rules: {
        dayOfBirth: [
          v => !!v || this.$t('rules.isRequired'),
          v => (checkNumber(v) && isValidDate(parseInt(v), this.monthOfBirth, this.yearOfBirth)) || this.$t('rules.invalid')
        ],
        text: [
          v => v?.trim().length < 70 || this.$t('rules.invalid'),
          v => !!v || this.$t('rules.isRequired')
        ],
        optionalText: [
          v => !v || v?.trim().length < 70 || this.$t('rules.invalid')
        ],
        password: [
          v => !!v || this.$t('rules.isRequired'),
          v => checkPassword(v) || this.$t('rules.passwordRules')
        ],
        name: [
          v => !!v || this.$t('rules.isRequired'),
          v => v?.trim().length < 25 || this.$t('rules.invalid')
        ],
        phoneNumber: [
          v => !!v || this.$t('rules.isRequired'),
          v => checkPhoneNumber(v) || this.$t('rules.invalid')
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .year-select {
    min-width: 93px !important;
  }

  .v-input--checkbox {
    i {
      background: white;
      font-size: var(--font-size__large--szlx);
    }

    label {
      font-weight: var(--font-weight__bold) !important;
      color: var(--color__primary-light);
    }
  }

  .v-radio {
    label {
      font-weight: var(--font-weight__medium) !important;
      color: var(--color__medium-gray) !important;
    }
  }
}
</style>
